
import Navbar from "./components/navbar.js";

import Footer from "./components/footer";
import About from "./components/About";
import Services from "./components/services";
import HeroSection from "./components/Hero";
import { ThemeProvider } from '../src/components/ThemeContext';


function App() {
	return (
		<ThemeProvider>

		<div className="z-50 fixed top-0 left-0 w-full h-fit">
		  <Navbar />
		</div>
		<div className="pt-16">
		  <HeroSection />
		  <Services />
		  <About />
		  <Footer />
		</div>

	  </ThemeProvider>
	);
  }
  
  export default App;

