import React from "react";
import light from "../images/light.webp";
import dark from "../images/dark.webp";
import { useTheme } from "./ThemeContext";

const About = () => {
	const { theme } = useTheme();
	return (
		<section>
			<div
				className={`mx-auto max-w-full px-4 py-16 sm:px-6 lg:px-8 ${
					theme === "dark" ? "bg-black" : "bg-white"
				}`}
			>
				<div
					className={`grid grid-cols-1 lg:h-screen lg:grid-cols-2 ${
						theme === "dark" ? "bg-black" : "bg-white"
					}`}
				>
					<div className={`relative z-10 lg:py-16 `}>
						<div className="relative h-64 sm:h-80 lg:h-full rounded-xl">
							<img
								alt=""
								src={theme === "dark" ? dark : light}
								className="absolute inset-0 h-full w-full object-cover rounded-xl"
							/>
						</div>
					</div>

					<div
						className={`relative flex items-center ${
							theme === "dark"
								? "bg-black border-black"
								: "bg-gray-100"
						}`}
					>
						<span
							className={`hidden lg:absolute lg:inset-y-0 lg:-start-16 lg:block lg:w-16  ${
								theme === "dark"
									? "bg-black border-black"
									: "bg-gray-100"
							}`}
						></span>

						<div className={`p-8 sm:p-16 lg:p-24`}>
							<h2
								className={`text-2xl font-bold sm:text-3xl text-center ${
									theme === "dark"
										? "text-white"
										: " text-black"
								} `}
							>
								ABOUT US
							</h2>

							<p
								className={`mt-4  text-pretty  ${
									theme === "dark"
										? "text-white"
										: "text-gray-500"
								}`}
							>
								PAI Solutions is a leading Data Science and Big
								Data Company that offers training and consulting
								services. We started in 2019 with a vision to
								empower professionals and businesses with
								cutting-edge data skills. Since then, we have
								trained over 650+ individuals and helped over
								10+ companies across various domains to leverage
								data for growth and innovation.
								<br />
								<br />
								We are always looking for new challenges and
								opportunities to partner with organizations that
								share our passion for data. Our expertise and
								experience in delivering tailor-made data
								solutions make us a reliable and trusted partner
								for your business needs. Let's connect and
								explore how we can work together to achieve your
								data goals.
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
export default About;
