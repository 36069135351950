import React from "react";

import tower from '../images/tower.webp';
import { useTheme } from './ThemeContext';

const HeroSection = () => {
    const { theme } = useTheme();
    const backgroundImageStyle = {
        backgroundImage: `url(${tower})`,
    };

    return (
        <div className={`hero sm:h-72 md:h-96  lg:min-h-1/2 bg-contain bg-center  dark:mix-blend-luminosity `}style={backgroundImageStyle}>
            <div className="hero-overlay bg-opacity-10"></div>
            <div className="hero-content text-neutral-content text-center">
                <div className=" max-w-screen-xl">
                    <h1 className="mb-5 text-5xl font-bold text-indigo-500 dark:text-white">Crafting Insights From Complexity</h1>
                    <p className="mb-5 text-xl text-white">
                    Empower Business with AI-Driven Insights and Big Data to Transform Customer Experiences.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default HeroSection;
